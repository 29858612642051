export const saleTypes = [
    {
        title: 'For Sale',
        heroTitle: 'Sale',
        value: 'sale'
    },
    {
        title: 'For Rent',
        heroTitle: 'Rent',
        value: 'rent'
    }
]

export const sellSaleTypes = [
    {
        title: 'Sell',
        value: 'sale'
    },
    {
        title: 'Rent',
        value: 'rent'
    }
]

export const btsList = [
    { "id": '', "type": "", "stationName": "All Stations", "value": '1' },
    {
        "id": "649654b1790d18683099d6c7",
        "type": "BTS",
        "stationName": "11th Infantry Regiment",
        "colorCode": "#7db71f",
        "value": "2"
    },
    {
        "id": "64965667790d18683099d75d",
        "type": "BTS",
        "stationName": "Ari",
        "colorCode": "#7db71f",
        "value": "3"
    },
    {
        "id": "64964dc8790d18683099d3fe",
        "type": "BTS",
        "stationName": "Asok",
        "colorCode": "#7db71f",
        "value": "4"
    },
    {
        "id": "64964b2b790d18683099d37d",
        "type": "ARL",
        "stationName": "Ban Thap Chang",
        "colorCode": "#701b1f",
        "value": "5"
    },
    {
        "id": "649654de790d18683099d6d6",
        "type": "BTS",
        "stationName": "Bang Bua",
        "colorCode": "#7db71f",
        "value": "6"
    },
    {
        "id": "64964e81790d18683099d458",
        "type": "BTS",
        "stationName": "Bang Chak",
        "colorCode": "#7db71f",
        "value": "7"
    },
    {
        "id": "649661a7790d18683099db6e",
        "type": "MRT",
        "stationName": "Bang Kapi",
        "colorCode": "#f4db00",
        "value": "8"
    },
    {
        "id": "64965cd6790d18683099d9d7",
        "type": "MRT",
        "stationName": "Bang Khae",
        "colorCode": "#1264b8",
        "value": "9"
    },
    {
        "id": "649658e9790d18683099d7d9",
        "type": "MRT",
        "stationName": "Bang Khun Non",
        "colorCode": "#1264b8",
        "value": "10"
    },
    {
        "id": "64965ffe790d18683099da9c",
        "type": "MRT",
        "stationName": "Bang Krasor",
        "colorCode": "#660066",
        "value": "11"
    },
    {
        "id": "64964eef790d18683099d485",
        "type": "BTS",
        "stationName": "Bang Na",
        "colorCode": "#7db71f",
        "value": "12"
    },
    {
        "id": "6496595f790d18683099d815",
        "type": "MRT",
        "stationName": "Bang O",
        "colorCode": "#1264b8",
        "value": "13"
    },
    {
        "id": "64965c2d790d18683099d99b",
        "type": "MRT",
        "stationName": "Bang Phai",
        "colorCode": "#1264b8",
        "value": "14"
    },
    {
        "id": "64965942790d18683099d806",
        "type": "MRT",
        "stationName": "Bang Phlat",
        "colorCode": "#1264b8",
        "value": "15"
    },
    {
        "id": "64965f6d790d18683099da42",
        "type": "MRT",
        "stationName": "Bang Phlu",
        "colorCode": "#660066",
        "value": "16"
    },
    {
        "id": "6496597c790d18683099d824",
        "type": "MRT",
        "stationName": "Bang Pho",
        "colorCode": "#1264b8",
        "value": "17"
    },
    {
        "id": "64965f9e790d18683099da60",
        "type": "MRT",
        "stationName": "Bang Rak Noi Tha It",
        "colorCode": "#660066",
        "value": "18"
    },
    {
        "id": "64965f83790d18683099da51",
        "type": "MRT",
        "stationName": "Bang Rak Yai",
        "colorCode": "#660066",
        "value": "19"
    },
    {
        "id": "64966077790d18683099dae7",
        "type": "MRT",
        "stationName": "Bang Son \t",
        "colorCode": "#660066",
        "value": "20"
    },
    {
        "id": "649659a7790d18683099d842",
        "type": "MRT",
        "stationName": "Bang Sue",
        "colorCode": "#1264b8",
        "value": "21"
    },
    {
        "id": "64965222790d18683099d613",
        "type": "BTS",
        "stationName": "Bang Wa",
        "colorCode": "#007c79",
        "value": "22"
    },
    {
        "id": "64965c67790d18683099d9aa",
        "type": "MRT",
        "stationName": "Bang Wa",
        "colorCode": "#1264b8",
        "value": "23"
    },
    {
        "id": "6496590c790d18683099d7e8",
        "type": "MRT",
        "stationName": "Bang Yi Khan",
        "colorCode": "#1264b8",
        "value": "24"
    },
    {
        "id": "64964f12790d18683099d494",
        "type": "BTS",
        "stationName": "Bearing",
        "colorCode": "#7db71f",
        "value": "25"
    },
    {
        "id": "649653d8790d18683099d67c",
        "type": "BTS",
        "stationName": "Bhumibol Adulyadej Hospital",
        "colorCode": "#7db71f",
        "value": "26"
    },
    {
        "id": "64964f6f790d18683099d4c1",
        "type": "BTS",
        "stationName": "Chang Erawan",
        "colorCode": "#7db71f",
        "value": "27"
    },
    {
        "id": "649658b1790d18683099d7bb",
        "type": "MRT",
        "stationName": "Charan 13",
        "colorCode": "#1264b8",
        "value": "28"
    },
    {
        "id": "64965286790d18683099d631",
        "type": "BTS",
        "stationName": "Charoen Nakhon",
        "colorCode": "#cbad5c",
        "value": "29"
    },
    {
        "id": "649659e3790d18683099d860",
        "type": "MRT",
        "stationName": "Chatuchak Park",
        "colorCode": "#1264b8",
        "value": "30"
    },
    {
        "id": "64964d5e790d18683099d3cd",
        "type": "BTS",
        "stationName": "Chit Lom",
        "colorCode": "#7db71f",
        "value": "31"
    },
    {
        "id": "64966110790d18683099db23",
        "type": "MRT",
        "stationName": "Chok Chai 4",
        "colorCode": "#f4db00",
        "value": "32"
    },
    {
        "id": "6496512e790d18683099d58c",
        "type": "BTS",
        "stationName": "Chong Nonsi",
        "colorCode": "#007c79",
        "value": "33"
    },
    {
        "id": "64964e26790d18683099d42b",
        "type": "BTS",
        "stationName": "Ekkamai",
        "colorCode": "#7db71f",
        "value": "34"
    },
    {
        "id": "649658d5790d18683099d7ca",
        "type": "MRT",
        "stationName": "Fai Chai",
        "colorCode": "#1264b8",
        "value": "35"
    },
    {
        "id": "649655cb790d18683099d730",
        "type": "BTS",
        "stationName": "Ha Yaek Lat Phrao",
        "colorCode": "#7db71f",
        "value": "36"
    },
    {
        "id": "64965b8a790d18683099d941",
        "type": "MRT",
        "stationName": "Hua Lamphong",
        "colorCode": "#1264b8",
        "value": "37"
    },
    {
        "id": "64964b01790d18683099d36e",
        "type": "ARL",
        "stationName": "Hua Mak",
        "colorCode": "#701b1f",
        "value": "38"
    },
    {
        "id": "649661fe790d18683099db9b",
        "type": "MRT",
        "stationName": "Hua Mak",
        "colorCode": "#f4db00",
        "value": "39"
    },
    {
        "id": "64965a78790d18683099d8ab",
        "type": "MRT",
        "stationName": "Huai Khwang",
        "colorCode": "#1264b8",
        "value": "40"
    },
    {
        "id": "64965bfc790d18683099d97d",
        "type": "MRT",
        "stationName": "Itsaraphap",
        "colorCode": "#1264b8",
        "value": "41"
    },
    {
        "id": "649659cd790d18683099d851",
        "type": "MRT",
        "stationName": "Kamphaeng Phet",
        "colorCode": "#1264b8",
        "value": "42"
    },
    {
        "id": "64965535790d18683099d6f4",
        "type": "BTS",
        "stationName": "Kasetsart University",
        "colorCode": "#7db71f",
        "value": "43"
    },
    {
        "id": "64966231790d18683099dbaa",
        "type": "MRT",
        "stationName": "Kelantan",
        "colorCode": "#f4db00",
        "value": "44"
    },
    {
        "id": "6496503e790d18683099d51b",
        "type": "BTS",
        "stationName": "Kheha",
        "colorCode": "#7db71f",
        "value": "45"
    },
    {
        "id": "64965f1c790d18683099da15",
        "type": "MRT",
        "stationName": "Khlong Bang Phai",
        "colorCode": "#660066",
        "value": "46"
    },
    {
        "id": "649652a0790d18683099d640",
        "type": "BTS",
        "stationName": "Khlong San",
        "colorCode": "#cbad5c",
        "value": "47"
    },
    {
        "id": "64965b17790d18683099d905",
        "type": "MRT",
        "stationName": "Khlong Toei",
        "colorCode": "#1264b8",
        "value": "48"
    },
    {
        "id": "64965360790d18683099d64f",
        "type": "BTS",
        "stationName": "Khu Khot",
        "colorCode": "#7db71f",
        "value": "49"
    },
    {
        "id": "649651a6790d18683099d5c8",
        "type": "BTS",
        "stationName": "Krung Thon Buri",
        "colorCode": "#007c79",
        "value": "50"
    },
    {
        "id": "64965266790d18683099d622",
        "type": "BTS",
        "stationName": "Krung Thon Buri",
        "colorCode": "#cbad5c",
        "value": "51"
    },
    {
        "id": "64965cee790d18683099d9e6",
        "type": "MRT",
        "stationName": "Lak Song",
        "colorCode": "#1264b8",
        "value": "52"
    },
    {
        "id": "64964b5f790d18683099d38c",
        "type": "ARL",
        "stationName": "Lat Krabang",
        "colorCode": "#701b1f",
        "value": "53"
    },
    {
        "id": "64965a1f790d18683099d87e",
        "type": "MRT",
        "stationName": "Lat Phrao",
        "colorCode": "#1264b8",
        "value": "54"
    },
    {
        "id": "649660de790d18683099db05",
        "type": "MRT",
        "stationName": "Lat Phrao",
        "colorCode": "#f4db00",
        "value": "55"
    },
    {
        "id": "64966182790d18683099db5f",
        "type": "MRT",
        "stationName": "Lat Phrao 101",
        "colorCode": "#f4db00",
        "value": "56"
    },
    {
        "id": "64966139790d18683099db32",
        "type": "MRT",
        "stationName": "Lat Phrao 71",
        "colorCode": "#f4db00",
        "value": "57"
    },
    {
        "id": "64966150790d18683099db41",
        "type": "MRT",
        "stationName": "Lat Phrao 83",
        "colorCode": "#f4db00",
        "value": "58"
    },
    {
        "id": "64965b32790d18683099d914",
        "type": "MRT",
        "stationName": "Lumphini",
        "colorCode": "#1264b8",
        "value": "59"
    },
    {
        "id": "64966167790d18683099db50",
        "type": "MRT",
        "stationName": "Mahat Thai",
        "colorCode": "#f4db00",
        "value": "60"
    },
    {
        "id": "64964abe790d18683099d350",
        "type": "ARL",
        "stationName": "Makkasan",
        "colorCode": "#701b1f",
        "value": "61"
    },
    {
        "id": "64966030790d18683099daba",
        "type": "MRT",
        "stationName": "Ministry of Public Health",
        "colorCode": "#660066",
        "value": "62"
    },
    {
        "id": "64965614790d18683099d73f",
        "type": "BTS",
        "stationName": "Mo Chit",
        "colorCode": "#7db71f",
        "value": "63"
    },
    {
        "id": "64964db4790d18683099d3ef",
        "type": "BTS",
        "stationName": "Nana",
        "colorCode": "#7db71f",
        "value": "64"
    },
    {
        "id": "649650b6790d18683099d52a",
        "type": "BTS",
        "stationName": "National Stadium",
        "colorCode": "#007c79",
        "value": "65"
    },
    {
        "id": "64966016790d18683099daab",
        "type": "MRT",
        "stationName": "Nonthaburi Civic Center",
        "colorCode": "#660066",
        "value": "66"
    },
    {
        "id": "64964e65790d18683099d449",
        "type": "BTS",
        "stationName": "On Nut",
        "colorCode": "#7db71f",
        "value": "67"
    },
    {
        "id": "64964fb6790d18683099d4df",
        "type": "BTS",
        "stationName": "Pak Nam",
        "colorCode": "#7db71f",
        "value": "68"
    },
    {
        "id": "64965a03790d18683099d86f",
        "type": "MRT",
        "stationName": "Phahon Yothin",
        "colorCode": "#1264b8",
        "value": "69"
    },
    {
        "id": "649655af790d18683099d721",
        "type": "BTS",
        "stationName": "Phahon Yothin 24",
        "colorCode": "#7db71f",
        "value": "70"
    },
    {
        "id": "64965463790d18683099d6a9",
        "type": "BTS",
        "stationName": "Phahon Yothin 59",
        "colorCode": "#7db71f",
        "value": "71"
    },
    {
        "id": "64965cb8790d18683099d9c8",
        "type": "MRT",
        "stationName": "Phasi Charoen",
        "colorCode": "#1264b8",
        "value": "72"
    },
    {
        "id": "649660f7790d18683099db14",
        "type": "MRT",
        "stationName": "Phawana",
        "colorCode": "#f4db00",
        "value": "73"
    },
    {
        "id": "64964a72790d18683099d332",
        "type": "ARL",
        "stationName": "Phaya Thai",
        "colorCode": "#701b1f",
        "value": "74"
    },
    {
        "id": "649656b2790d18683099d78a",
        "type": "BTS",
        "stationName": "Phaya Thai",
        "colorCode": "#7db71f",
        "value": "75"
    },
    {
        "id": "64965ac4790d18683099d8d8",
        "type": "MRT",
        "stationName": "Phetchaburi",
        "colorCode": "#1264b8",
        "value": "76"
    },
    {
        "id": "64965c95790d18683099d9b9",
        "type": "MRT",
        "stationName": "Phetkasem 48",
        "colorCode": "#1264b8",
        "value": "77"
    },
    {
        "id": "64964d71790d18683099d3dc",
        "type": "BTS",
        "stationName": "Phloen Chit",
        "colorCode": "#7db71f",
        "value": "78"
    },
    {
        "id": "649651dc790d18683099d5e6",
        "type": "BTS",
        "stationName": "Pho Nimit",
        "colorCode": "#007c79",
        "value": "79"
    },
    {
        "id": "64964e45790d18683099d43a",
        "type": "BTS",
        "stationName": "Phra Khanong",
        "colorCode": "#7db71f",
        "value": "80"
    },
    {
        "id": "64965fcb790d18683099da7e",
        "type": "MRT",
        "stationName": "Phra Nang Klao Bridge",
        "colorCode": "#660066",
        "value": "81"
    },
    {
        "id": "64965aa7790d18683099d8c9",
        "type": "MRT",
        "stationName": "Phra Ram 9",
        "colorCode": "#1264b8",
        "value": "82"
    },
    {
        "id": "64965009790d18683099d4fd",
        "type": "BTS",
        "stationName": "Phraek Sa",
        "colorCode": "#7db71f",
        "value": "83"
    },
    {
        "id": "64964de3790d18683099d40d",
        "type": "BTS",
        "stationName": "Phrom Phong",
        "colorCode": "#7db71f",
        "value": "84"
    },
    {
        "id": "64964f51790d18683099d4b2",
        "type": "BTS",
        "stationName": "Pu Chao",
        "colorCode": "#7db71f",
        "value": "85"
    },
    {
        "id": "64964ea4790d18683099d467",
        "type": "BTS",
        "stationName": "Punnawithi",
        "colorCode": "#7db71f",
        "value": "86"
    },
    {
        "id": "64965b01790d18683099d8f6",
        "type": "MRT",
        "stationName": "Queen Sirikit National Convention Centre",
        "colorCode": "#1264b8",
        "value": "87"
    },
    {
        "id": "64964ade790d18683099d35f",
        "type": "ARL",
        "stationName": "RamkhamHaeng",
        "colorCode": "#701b1f",
        "value": "88"
    },
    {
        "id": "64965103790d18683099d56e",
        "type": "BTS",
        "stationName": "Ratchadamri",
        "colorCode": "#007c79",
        "value": "89"
    },
    {
        "id": "64965a36790d18683099d88d",
        "type": "MRT",
        "stationName": "Ratchadaphisek",
        "colorCode": "#1264b8",
        "value": "90"
    },
    {
        "id": "64964a94790d18683099d341",
        "type": "ARL",
        "stationName": "Ratchaprarop",
        "colorCode": "#701b1f",
        "value": "91"
    },
    {
        "id": "649656c8790d18683099d799",
        "type": "BTS",
        "stationName": "Ratchathewi",
        "colorCode": "#7db71f",
        "value": "92"
    },
    {
        "id": "64965590790d18683099d712",
        "type": "BTS",
        "stationName": "Ratchayothin",
        "colorCode": "#7db71f",
        "value": "93"
    },
    {
        "id": "6496550c790d18683099d6e5",
        "type": "BTS",
        "stationName": "Royal Forest Department",
        "colorCode": "#7db71f",
        "value": "94"
    },
    {
        "id": "649653ad790d18683099d66d",
        "type": "BTS",
        "stationName": "Royal Thai Air Force Museum",
        "colorCode": "#7db71f",
        "value": "95"
    },
    {
        "id": "64964f97790d18683099d4d0",
        "type": "BTS",
        "stationName": "Royal Thai Naval Academy",
        "colorCode": "#7db71f",
        "value": "96"
    },
    {
        "id": "64965024790d18683099d50c",
        "type": "BTS",
        "stationName": "Sai Luat",
        "colorCode": "#7db71f",
        "value": "97"
    },
    {
        "id": "64965fb6790d18683099da6f",
        "type": "MRT",
        "stationName": "Sai Ma",
        "colorCode": "#660066",
        "value": "98"
    },
    {
        "id": "64965434790d18683099d69a",
        "type": "BTS",
        "stationName": "Sai Yud",
        "colorCode": "#7db71f",
        "value": "99"
    },
    {
        "id": "64965150790d18683099d59b",
        "type": "BTS",
        "stationName": "Saint Louis",
        "colorCode": "#007c79",
        "value": "100"
    },
    {
        "id": "64965118790d18683099d57d",
        "type": "BTS",
        "stationName": "Sala Daeng",
        "colorCode": "#007c79",
        "value": "101"
    },
    {
        "id": "64965f55790d18683099da33",
        "type": "MRT",
        "stationName": "Sam Yaek Bang Yai",
        "colorCode": "#660066",
        "value": "102"
    },
    {
        "id": "64965b75790d18683099d932",
        "type": "MRT",
        "stationName": "Sam Yan",
        "colorCode": "#1264b8",
        "value": "103"
    },
    {
        "id": "64965bc5790d18683099d95f",
        "type": "MRT",
        "stationName": "Sam Yot",
        "colorCode": "#1264b8",
        "value": "104"
    },
    {
        "id": "64964f39790d18683099d4a3",
        "type": "BTS",
        "stationName": "Samrong",
        "colorCode": "#7db71f",
        "value": "105"
    },
    {
        "id": "649663d5790d18683099dc53",
        "type": "MRT",
        "stationName": "Samrong",
        "colorCode": "#f4db00",
        "value": "106"
    },
    {
        "id": "64965be8790d18683099d96e",
        "type": "MRT",
        "stationName": "Sanam Chai",
        "colorCode": "#1264b8",
        "value": "107"
    },
    {
        "id": "6496567e790d18683099d76c",
        "type": "BTS",
        "stationName": "Sanam Pao",
        "colorCode": "#7db71f",
        "value": "108"
    },
    {
        "id": "64965633790d18683099d74e",
        "type": "BTS",
        "stationName": "Saphan Khwai",
        "colorCode": "#7db71f",
        "value": "109"
    },
    {
        "id": "64965401790d18683099d68b",
        "type": "BTS",
        "stationName": "Saphan Mai",
        "colorCode": "#7db71f",
        "value": "110"
    },
    {
        "id": "64965189790d18683099d5b9",
        "type": "BTS",
        "stationName": "Saphan Taksin",
        "colorCode": "#007c79",
        "value": "111"
    },
    {
        "id": "6496556a790d18683099d703",
        "type": "BTS",
        "stationName": "Sena Nikhom",
        "colorCode": "#7db71f",
        "value": "112"
    },
    {
        "id": "6496636c790d18683099dc17",
        "type": "MRT",
        "stationName": "Si Bearing",
        "colorCode": "#f4db00",
        "value": "113"
    },
    {
        "id": "6496638d790d18683099dc26",
        "type": "MRT",
        "stationName": "Si Dan",
        "colorCode": "#f4db00",
        "value": "114"
    },
    {
        "id": "64966327790d18683099dbf9",
        "type": "MRT",
        "stationName": "Si Iam",
        "colorCode": "#f4db00",
        "value": "115"
    },
    {
        "id": "649661e2790d18683099db8c",
        "type": "MRT",
        "stationName": "Si Kritha",
        "colorCode": "#f4db00",
        "value": "116"
    },
    {
        "id": "64966345790d18683099dc08",
        "type": "MRT",
        "stationName": "Si La Salle",
        "colorCode": "#f4db00",
        "value": "117"
    },
    {
        "id": "64965b47790d18683099d923",
        "type": "MRT",
        "stationName": "Si Lom",
        "colorCode": "#1264b8",
        "value": "118"
    },
    {
        "id": "649662ab790d18683099dbbd",
        "type": "MRT",
        "stationName": "Si Nut",
        "colorCode": "#f4db00",
        "value": "119"
    },
    {
        "id": "649663a5790d18683099dc35",
        "type": "MRT",
        "stationName": "Si Thepha",
        "colorCode": "#f4db00",
        "value": "120"
    },
    {
        "id": "64966304790d18683099dbea",
        "type": "MRT",
        "stationName": "Si Udom",
        "colorCode": "#f4db00",
        "value": "121"
    },
    {
        "id": "64964d3e790d18683099d3be",
        "type": "BTS",
        "stationName": "Siam",
        "colorCode": "#7db71f",
        "value": "122"
    },
    {
        "id": "649650ed790d18683099d55f",
        "type": "BTS",
        "stationName": "Siam",
        "colorCode": "#007c79",
        "value": "123"
    },
    {
        "id": "64965924790d18683099d7f7",
        "type": "MRT",
        "stationName": "Sirindhorn",
        "colorCode": "#1264b8",
        "value": "124"
    },
    {
        "id": "64964fd3790d18683099d4ee",
        "type": "BTS",
        "stationName": "Srinagarindra",
        "colorCode": "#7db71f",
        "value": "125"
    },
    {
        "id": "649662c6790d18683099dbcc",
        "type": "MRT",
        "stationName": "Srinagarindra 38",
        "colorCode": "#f4db00",
        "value": "126"
    },
    {
        "id": "649662df790d18683099dbdb",
        "type": "MRT",
        "stationName": "Suan Luang Rama IX",
        "colorCode": "#f4db00",
        "value": "127"
    },
    {
        "id": "64965ae1790d18683099d8e7",
        "type": "MRT",
        "stationName": "Sukhumvit",
        "colorCode": "#1264b8",
        "value": "128"
    },
    {
        "id": "64965171790d18683099d5aa",
        "type": "BTS",
        "stationName": "Surasak",
        "colorCode": "#007c79",
        "value": "129"
    },
    {
        "id": "64965a56790d18683099d89c",
        "type": "MRT",
        "stationName": "Sutthisan",
        "colorCode": "#1264b8",
        "value": "130"
    },
    {
        "id": "64964b90790d18683099d39b",
        "type": "ARL",
        "stationName": "Suvarnabhumi",
        "colorCode": "#701b1f",
        "value": "131"
    },
    {
        "id": "64965f38790d18683099da24",
        "type": "MRT",
        "stationName": "Talad Bang Yai",
        "colorCode": "#660066",
        "value": "132"
    },
    {
        "id": "649651f9790d18683099d5f5",
        "type": "BTS",
        "stationName": "Talat Phlu",
        "colorCode": "#007c79",
        "value": "133"
    },
    {
        "id": "64965994790d18683099d833",
        "type": "MRT",
        "stationName": "Tao Poon",
        "colorCode": "#1264b8",
        "value": "134"
    },
    {
        "id": "6496608f790d18683099daf6",
        "type": "MRT",
        "stationName": "Tao Poon",
        "colorCode": "#660066",
        "value": "135"
    },
    {
        "id": "64965882790d18683099d7ac",
        "type": "MRT",
        "stationName": "Tha Phra",
        "colorCode": "#1264b8",
        "value": "136"
    },
    {
        "id": "64965c15790d18683099d98c",
        "type": "MRT",
        "stationName": "Tha Phra",
        "colorCode": "#1264b8",
        "value": "137"
    },
    {
        "id": "64965a90790d18683099d8ba",
        "type": "MRT",
        "stationName": "Thailand Cultural Centre",
        "colorCode": "#1264b8",
        "value": "138"
    },
    {
        "id": "649663bc790d18683099dc44",
        "type": "MRT",
        "stationName": "Thipphawan",
        "colorCode": "#f4db00",
        "value": "139"
    },
    {
        "id": "64964e0a790d18683099d41c",
        "type": "BTS",
        "stationName": "Thong Lor",
        "colorCode": "#7db71f",
        "value": "140"
    },
    {
        "id": "64964ebc790d18683099d476",
        "type": "BTS",
        "stationName": "Udom Suk",
        "colorCode": "#7db71f",
        "value": "141"
    },
    {
        "id": "64965697790d18683099d77b",
        "type": "BTS",
        "stationName": "Victory Monument",
        "colorCode": "#7db71f",
        "value": "142"
    },
    {
        "id": "64965ba2790d18683099d950",
        "type": "MRT",
        "stationName": "Wat Mangkon",
        "colorCode": "#1264b8",
        "value": "143"
    },
    {
        "id": "6496548d790d18683099d6b8",
        "type": "BTS",
        "stationName": "Wat Phra Sri Mahathat",
        "colorCode": "#7db71f",
        "value": "144"
    },
    {
        "id": "64966064790d18683099dad8",
        "type": "MRT",
        "stationName": "Wong Sawang",
        "colorCode": "#660066",
        "value": "145"
    },
    {
        "id": "649651be790d18683099d5d7",
        "type": "BTS",
        "stationName": "Wongwian Yai",
        "colorCode": "#007c79",
        "value": "146"
    },
    {
        "id": "6496520d790d18683099d604",
        "type": "BTS",
        "stationName": "Wutthakat",
        "colorCode": "#007c79",
        "value": "147"
    },
    {
        "id": "64965387790d18683099d65e",
        "type": "BTS",
        "stationName": "Yaek Kor Por Aor",
        "colorCode": "#7db71f",
        "value": "148"
    },
    {
        "id": "649661c2790d18683099db7d",
        "type": "MRT",
        "stationName": "Yaek Lam Sali",
        "colorCode": "#f4db00",
        "value": "149"
    },
    {
        "id": "64965fe8790d18683099da8d",
        "type": "MRT",
        "stationName": "Yaek Nonthaburi 1",
        "colorCode": "#660066",
        "value": "150"
    },
    {
        "id": "64966047790d18683099dac9",
        "type": "MRT",
        "stationName": "Yaek Tiwanon",
        "colorCode": "#660066",
        "value": "151"
    }
]

export const categoryTypes = [
    {
        title: 'Any',
        value: 'all'
    },
    {
        title: 'Villa',
        value: 'house'
    },
    {
        title: 'Land',
        value: 'land'
    },
    {
        title: 'Condo',
        value: 'condo'
    },

    {
        title: 'Commercial',
        value: 'commercial'
    }
]
export const heroCategoryTypes = [
    {
        title: 'Villa',
        value: 'house'
    },
    {
        title: 'Land',
        value: 'land'
    },
    {
        title: 'Condo',
        value: 'condo'
    },
    {
        title: 'Commercial',
        value: 'commercial'
    }
]

export const roomTypes = [
    {
        title: 'Any',
        value: '0'
    },
    {
        title: 'Studio',
        value: 'studio'
    },
    {
        title: '1+',
        value: '1'
    },
    {
        title: '2+',
        value: '2'
    },
    {
        title: '3+',
        value: '3'
    },
    {
        title: '4+',
        value: '4'
    },
]
export const sortTypes = [
    {
        title: 'Newest',
        value: 'datedesc'
    },
    {
        title: 'Price Asc',
        value: 'priceasc'
    },
    {
        title: 'Price Desc',
        value: 'pricedesc'
    }
]

export const roomHouseTypes = [
    {
        title: 'Any',
        value: '0'
    },
    {
        title: '1+',
        value: '1'
    },
    {
        title: '2+',
        value: '2'
    },
    {
        title: '3+',
        value: '3'
    },
    {
        title: '4+',
        value: '4'
    },
]

export const minBedrooms = [
    {
        title: 'Any',
        value: '0'
    },
    {
        title: 'Studio',
        value: 'studio'
    },
    {
        title: '1',
        value: '1'
    },
    {
        title: '2',
        value: '2'
    },
    {
        title: '3',
        value: '3'
    },
    {
        title: '4',
        value: '4'
    },
    {
        title: '4 and Above',
        value: '4+'
    }
]

export const maxBedrooms = [
    {
        title: 'Any',
        value: '0'
    },
    {
        title: '1',
        value: '1'
    },
    {
        title: '2',
        value: '2'
    },
    {
        title: '3',
        value: '3'
    },
    {
        title: '4',
        value: '4'
    },
    {
        title: '4 and Above',
        value: '4+'
    }
]

export const currencies = [
    {
        title: 'Thai Baht',
        value: 'thb',
        symbol: '฿'
    },
    {
        title: 'US Dollar',
        value: 'usd',
        symbol: '$'
    },
    {
        title: 'Euro',
        value: 'eur',
        symbol: '€'
    },
    {
        title: 'Chinese Yuan',
        value: 'rmb',
        symbol: 'CNY'
    },
    {
        title: 'GB Pound',
        value: 'gbp',
        symbol: '£'
    },
    {
        title: 'Australian Dollar',
        value: 'aud',
        symbol: 'AU$'
    },
    {
        title: 'Japanese Yen',
        value: 'jpy',
        symbol: '¥'
    }
]
export const saleMinPrices = {
    "en": [
        {
            value: '0',
            nom: 'Any'
        },
        {
            value: '1000000',
            nom: ' 1,000,000'
        },
        {
            value: '2000000',
            nom: ' 2,000,000'
        },
        {
            value: '3000000',
            nom: ' 3,000,000'
        },
        {
            value: '4000000',
            nom: ' 4,000,000'
        },
        {
            value: '5000000',
            nom: ' 5,000,000'
        },
        {
            value: '6000000',
            nom: ' 6,000,000'
        },
        {
            value: '7000000',
            nom: ' 7,000,000'
        },
        {
            value: '8000000',
            nom: ' 8,000,000'
        },
        {
            value: '9000000',
            nom: ' 9,000,000'
        },
        {
            value: '10000000',
            nom: ' 10,000,000'
        },
        {
            value: '12000000',
            nom: ' 12,000,000'
        },
        {
            value: '15000000',
            nom: ' 15,000,000'
        },
        {
            value: '20000000',
            nom: ' 20,000,000'
        },
        {
            value: '25000000',
            nom: ' 25,000,000'
        },
        {
            value: '30000000',
            nom: ' 30,000,000'
        },
        {
            value: '35000000',
            nom: ' 35,000,000'
        }
    ],
    "th": [
        {
            value: '0',
            nom: 'อื่นๆ'
        },
        {
            value: '1000000',
            nom: ' 1,000,000'
        },
        {
            value: '2000000',
            nom: ' 2,000,000'
        },
        {
            value: '3000000',
            nom: ' 3,000,000'
        },
        {
            value: '4000000',
            nom: ' 4,000,000'
        },
        {
            value: '5000000',
            nom: ' 5,000,000'
        },
        {
            value: '6000000',
            nom: ' 6,000,000'
        },
        {
            value: '7000000',
            nom: ' 7,000,000'
        },
        {
            value: '8000000',
            nom: ' 8,000,000'
        },
        {
            value: '9000000',
            nom: ' 9,000,000'
        },
        {
            value: '10000000',
            nom: ' 10,000,000'
        },
        {
            value: '12000000',
            nom: ' 12,000,000'
        },
        {
            value: '15000000',
            nom: ' 15,000,000'
        },
        {
            value: '20000000',
            nom: ' 20,000,000'
        },
        {
            value: '25000000',
            nom: ' 25,000,000'
        },
        {
            value: '30000000',
            nom: ' 30,000,000'
        },
        {
            value: '35000000',
            nom: ' 35,000,000'
        }
    ],
    "cn": [
        {
            value: '0',
            nom: '无线'
        },
        {
            value: '1000000',
            nom: ' 1,000,000'
        },
        {
            value: '2000000',
            nom: ' 2,000,000'
        },
        {
            value: '3000000',
            nom: ' 3,000,000'
        },
        {
            value: '4000000',
            nom: ' 4,000,000'
        },
        {
            value: '5000000',
            nom: ' 5,000,000'
        },
        {
            value: '6000000',
            nom: ' 6,000,000'
        },
        {
            value: '7000000',
            nom: ' 7,000,000'
        },
        {
            value: '8000000',
            nom: ' 8,000,000'
        },
        {
            value: '9000000',
            nom: ' 9,000,000'
        },
        {
            value: '10000000',
            nom: ' 10,000,000'
        },
        {
            value: '12000000',
            nom: ' 12,000,000'
        },
        {
            value: '15000000',
            nom: ' 15,000,000'
        },
        {
            value: '20000000',
            nom: ' 20,000,000'
        },
        {
            value: '25000000',
            nom: ' 25,000,000'
        },
        {
            value: '30000000',
            nom: ' 30,000,000'
        },
        {
            value: '35000000',
            nom: ' 35,000,000'
        }
    ]
}
export const saleMaxPrices = {
    "en": [
        {
            value: '1000000',
            nom: ' 1,000,000'
        },
        {
            value: '2000000',
            nom: ' 2,000,000'
        },
        {
            value: '3000000',
            nom: ' 3,000,000'
        },
        {
            value: '4000000',
            nom: ' 4,000,000'
        },
        {
            value: '5000000',
            nom: ' 5,000,000'
        },
        {
            value: '6000000',
            nom: ' 6,000,000'
        },
        {
            value: '7000000',
            nom: ' 7,000,000'
        },
        {
            value: '8000000',
            nom: ' 8,000,000'
        },
        {
            value: '9000000',
            nom: ' 9,000,000'
        },
        {
            value: '10000000',
            nom: ' 10,000,000'
        },
        {
            value: '12000000',
            nom: ' 12,000,000'
        },
        {
            value: '15000000',
            nom: ' 15,000,000'
        },
        {
            value: '20000000',
            nom: ' 20,000,000'
        },
        {
            value: '25000000',
            nom: ' 25,000,000'
        },
        {
            value: '30000000',
            nom: ' 30,000,000'
        },
        {
            value: '35000000',
            nom: ' 35,000,000'
        },
        {
            value: '',
            nom: 'Any'
        },
    
    ],
    "th": [
        {
            value: '1000000',
            nom: ' 1,000,000'
        },
        {
            value: '2000000',
            nom: ' 2,000,000'
        },
        {
            value: '3000000',
            nom: ' 3,000,000'
        },
        {
            value: '4000000',
            nom: ' 4,000,000'
        },
        {
            value: '5000000',
            nom: ' 5,000,000'
        },
        {
            value: '6000000',
            nom: ' 6,000,000'
        },
        {
            value: '7000000',
            nom: ' 7,000,000'
        },
        {
            value: '8000000',
            nom: ' 8,000,000'
        },
        {
            value: '9000000',
            nom: ' 9,000,000'
        },
        {
            value: '10000000',
            nom: ' 10,000,000'
        },
        {
            value: '12000000',
            nom: ' 12,000,000'
        },
        {
            value: '15000000',
            nom: ' 15,000,000'
        },
        {
            value: '20000000',
            nom: ' 20,000,000'
        },
        {
            value: '25000000',
            nom: ' 25,000,000'
        },
        {
            value: '30000000',
            nom: ' 30,000,000'
        },
        {
            value: '35000000',
            nom: ' 35,000,000'
        },
        {
            value: '',
            nom: 'อื่นๆ'
        },
    
    ],
    "cn": [
        {
            value: '1000000',
            nom: ' 1,000,000'
        },
        {
            value: '2000000',
            nom: ' 2,000,000'
        },
        {
            value: '3000000',
            nom: ' 3,000,000'
        },
        {
            value: '4000000',
            nom: ' 4,000,000'
        },
        {
            value: '5000000',
            nom: ' 5,000,000'
        },
        {
            value: '6000000',
            nom: ' 6,000,000'
        },
        {
            value: '7000000',
            nom: ' 7,000,000'
        },
        {
            value: '8000000',
            nom: ' 8,000,000'
        },
        {
            value: '9000000',
            nom: ' 9,000,000'
        },
        {
            value: '10000000',
            nom: ' 10,000,000'
        },
        {
            value: '12000000',
            nom: ' 12,000,000'
        },
        {
            value: '15000000',
            nom: ' 15,000,000'
        },
        {
            value: '20000000',
            nom: ' 20,000,000'
        },
        {
            value: '25000000',
            nom: ' 25,000,000'
        },
        {
            value: '30000000',
            nom: ' 30,000,000'
        },
        {
            value: '35000000',
            nom: ' 35,000,000'
        },
        {
            value: '',
            nom: '无线'
        },
    
    ]
}
export const rentMinPrices = {
    "en":[
        {
            value: '0',
            nom: 'Any'
        },
        {
            value: '10000',
            nom: '10,000'
        },
        {
            value: '20000',
            nom: '20,000'
        },
        {
            value: '30000',
            nom: '30,000'
        },
        {
            value: '40000',
            nom: '40,000'
        },
        {
            value: '50000',
            nom: '50,000'
        },
        {
            value: '60000',
            nom: '60,000'
        },
        {
            value: '70000',
            nom: '70,000'
        },
        {
            value: '80000',
            nom: '80,000'
        },
        {
            value: '90000',
            nom: '90,000'
        },
        {
            value: '100000',
            nom: '100,000'
        },
        {
            value: '120000',
            nom: '120,000'
        },
        {
            value: '150000',
            nom: '150,000'
        },
        {
            value: '200000',
            nom: '200,000'
        },
        {
            value: '250000',
            nom: '250,000'
        },
        {
            value: '300000',
            nom: '300,000'
        },
        {
            value: '350000',
            nom: '350,000++'
        }
    
    ],
    "th":[
        {
            value: '0',
            nom: 'อื่นๆ'
        },
        {
            value: '10000',
            nom: '10,000'
        },
        {
            value: '20000',
            nom: '20,000'
        },
        {
            value: '30000',
            nom: '30,000'
        },
        {
            value: '40000',
            nom: '40,000'
        },
        {
            value: '50000',
            nom: '50,000'
        },
        {
            value: '60000',
            nom: '60,000'
        },
        {
            value: '70000',
            nom: '70,000'
        },
        {
            value: '80000',
            nom: '80,000'
        },
        {
            value: '90000',
            nom: '90,000'
        },
        {
            value: '100000',
            nom: '100,000'
        },
        {
            value: '120000',
            nom: '120,000'
        },
        {
            value: '150000',
            nom: '150,000'
        },
        {
            value: '200000',
            nom: '200,000'
        },
        {
            value: '250000',
            nom: '250,000'
        },
        {
            value: '300000',
            nom: '300,000'
        },
        {
            value: '350000',
            nom: '350,000++'
        }
    
    ],
    "cn":[
        {
            value: '0',
            nom: '无线'
        },
        {
            value: '10000',
            nom: '10,000'
        },
        {
            value: '20000',
            nom: '20,000'
        },
        {
            value: '30000',
            nom: '30,000'
        },
        {
            value: '40000',
            nom: '40,000'
        },
        {
            value: '50000',
            nom: '50,000'
        },
        {
            value: '60000',
            nom: '60,000'
        },
        {
            value: '70000',
            nom: '70,000'
        },
        {
            value: '80000',
            nom: '80,000'
        },
        {
            value: '90000',
            nom: '90,000'
        },
        {
            value: '100000',
            nom: '100,000'
        },
        {
            value: '120000',
            nom: '120,000'
        },
        {
            value: '150000',
            nom: '150,000'
        },
        {
            value: '200000',
            nom: '200,000'
        },
        {
            value: '250000',
            nom: '250,000'
        },
        {
            value: '300000',
            nom: '300,000'
        },
        {
            value: '350000',
            nom: '350,000++'
        }
    
    ],
}
export const rentMaxPrices = {
    "en":[
        {
            value: '10000',
            nom: '10,000'
        },
        {
            value: '20000',
            nom: '20,000'
        },
        {
            value: '30000',
            nom: '30,000'
        },
        {
            value: '40000',
            nom: '40,000'
        },
        {
            value: '50000',
            nom: '50,000'
        },
        {
            value: '60000',
            nom: '60,000'
        },
        {
            value: '70000',
            nom: '70,000'
        },
        {
            value: '80000',
            nom: '80,000'
        },
        {
            value: '90000',
            nom: '90,000'
        },
        {
            value: '100000',
            nom: '100,000'
        },
        {
            value: '120000',
            nom: '120,000'
        },
        {
            value: '150000',
            nom: '150,000'
        },
        {
            value: '200000',
            nom: '200,000'
        },
        {
            value: '250000',
            nom: '250,000'
        },
        {
            value: '300000',
            nom: '300,000'
        },
        {
            value: '350000',
            nom: '350,000'
        },
        {
            value: '',
            nom: 'Any'
        },
    ],
    "th":[
        {
            value: '10000',
            nom: '10,000'
        },
        {
            value: '20000',
            nom: '20,000'
        },
        {
            value: '30000',
            nom: '30,000'
        },
        {
            value: '40000',
            nom: '40,000'
        },
        {
            value: '50000',
            nom: '50,000'
        },
        {
            value: '60000',
            nom: '60,000'
        },
        {
            value: '70000',
            nom: '70,000'
        },
        {
            value: '80000',
            nom: '80,000'
        },
        {
            value: '90000',
            nom: '90,000'
        },
        {
            value: '100000',
            nom: '100,000'
        },
        {
            value: '120000',
            nom: '120,000'
        },
        {
            value: '150000',
            nom: '150,000'
        },
        {
            value: '200000',
            nom: '200,000'
        },
        {
            value: '250000',
            nom: '250,000'
        },
        {
            value: '300000',
            nom: '300,000'
        },
        {
            value: '350000',
            nom: '350,000'
        },
        {
            value: '',
            nom: 'อื่นๆ'
        },
    ],
    "cn":[
        {
            value: '10000',
            nom: '10,000'
        },
        {
            value: '20000',
            nom: '20,000'
        },
        {
            value: '30000',
            nom: '30,000'
        },
        {
            value: '40000',
            nom: '40,000'
        },
        {
            value: '50000',
            nom: '50,000'
        },
        {
            value: '60000',
            nom: '60,000'
        },
        {
            value: '70000',
            nom: '70,000'
        },
        {
            value: '80000',
            nom: '80,000'
        },
        {
            value: '90000',
            nom: '90,000'
        },
        {
            value: '100000',
            nom: '100,000'
        },
        {
            value: '120000',
            nom: '120,000'
        },
        {
            value: '150000',
            nom: '150,000'
        },
        {
            value: '200000',
            nom: '200,000'
        },
        {
            value: '250000',
            nom: '250,000'
        },
        {
            value: '300000',
            nom: '300,000'
        },
        {
            value: '350000',
            nom: '350,000'
        },
        {
            value: '',
            nom: '无线'
        },
    ],
}

export const serviceSaleTypes = {
    'en': [{
        title: 'Buy',
        heroTitle: 'Buy',
        value: 'sale'
    },
    {
        title: 'Rent',
        heroTitle: 'Rent',
        value: 'rent'
    }],
    'th': [{
        title: 'ซื้อ',
        heroTitle: 'Buy',
        value: 'sale'
    },
    {
        title: 'เช่า',
        heroTitle: 'Rent',
        value: 'rent'
    }],
    'cn': [{
        title: '买',
        heroTitle: '买',
        value: 'sale'
    },
    {
        title: '租',
        heroTitle: '租',
        value: 'rent'
    }],
}

export const preferredContact = [
    {
        name: 'Phone',
        value: 'phone'
    },
    {
        name: 'Email',
        value: 'email'
    },
    {
        name: 'LINE',
        value: 'line'
    },
    {
        name: 'Whatsapp',
        value: 'whatsapp'
    },
    {
        name: 'Wechat - 微信',
        value: 'wechat'
    },
    {
        name: 'Viber',
        value: 'viber'
    }
]

export const serviceCategoryTypes = {
    'en': [{
        title: 'Condo',
        value: 'condo'
    },
    {
        title: 'House',
        value: 'house'
    },
    {
        title: 'Land',
        value: 'land'
    },
    {
        title: 'Commercial',
        value: 'commercial'
    }],
    'th': [{
        title: 'คอนโด',
        value: 'condo'
    },
    {
        title: 'บ้าน',
        value: 'house'
    },
    {
        title: 'ที่ดิน',
        value: 'land'
    },
    {
        title: 'เชิงพาณิชย์',
        value: 'commercial'
    }],
    'cn': [{
        title: '公寓',
        value: 'condo'
    },
    {
        title: '别墅',
        value: 'house'
    },
    {
        title: '土地',
        value: 'land'
    },
    {
        title: '工商',
        value: 'commercial'
    }],
}

export const serviceCondoRoomTypes = {
    'en': [
        {
            title: 'Any',
            value: '0'
        },
        {
            title: 'Studio',
            value: 'studio'
        },
        {
            title: '1',
            value: '1'
        },
        {
            title: '2',
            value: '2'
        },
        {
            title: '3',
            value: '3'
        },
        {
            title: '4',
            value: '4'
        },
        {
            title: '5+',
            value: '5'
        },
    ],
    'th': [
        {
            title: 'ทั้งหมด',
            value: '0'
        },
        {
            title: 'สตูดิโอ',
            value: 'studio'
        },
        {
            title: '1',
            value: '1'
        },
        {
            title: '2',
            value: '2'
        },
        {
            title: '3',
            value: '3'
        },
        {
            title: '4',
            value: '4'
        },
        {
            title: '5+',
            value: '5'
        },
    ],
    'cn': [
        {
            title: '无线',
            value: '0'
        },
        {
            title: '开建户型',
            value: 'studio'
        },
        {
            title: '1',
            value: '1'
        },
        {
            title: '2',
            value: '2'
        },
        {
            title: '3',
            value: '3'
        },
        {
            title: '4',
            value: '4'
        },
        {
            title: '5+',
            value: '5'
        },
    ],

}
export const condoRoomTypes = {
    'en': [
        {
            title: 'Any',
            value: '0'
        },
        {
            title: 'Studio',
            value: 'studio'
        },
        {
            title: '1',
            value: '1'
        },
        {
            title: '2',
            value: '2'
        },
        {
            title: '3',
            value: '3'
        },
        {
            title: '4',
            value: '4'
        },
        {
            title: '5+',
            value: '5'
        },
    ],
    'th': [
        {
            title: 'ทั้งหมด',
            value: '0'
        },
        {
            title: 'สตูดิโอ',
            value: 'studio'
        },
        {
            title: '1',
            value: '1'
        },
        {
            title: '2',
            value: '2'
        },
        {
            title: '3',
            value: '3'
        },
        {
            title: '4',
            value: '4'
        },
        {
            title: '5+',
            value: '5'
        },
    ],
    'cn': [
        {
            title: '无线',
            value: '0'
        },
        {
            title: '开建户型',
            value: 'studio'
        },
        {
            title: '1',
            value: '1'
        },
        {
            title: '2',
            value: '2'
        },
        {
            title: '3',
            value: '3'
        },
        {
            title: '4',
            value: '4'
        },
        {
            title: '5+',
            value: '5'
        },
    ],

}
export const houseRoomTypes = {
    'en': [
        {
            title: '1',
            value: '1'
        },
        {
            title: '2',
            value: '2'
        },
        {
            title: '3',
            value: '3'
        },
        {
            title: '4',
            value: '4'
        },
        {
            title: '5+',
            value: '5'
        }
    ],
    'th': [
        {
            title: '1',
            value: '1'
        },
        {
            title: '2',
            value: '2'
        },
        {
            title: '3',
            value: '3'
        },
        {
            title: '4',
            value: '4'
        },
        {
            title: '5+',
            value: '5'
        }
    ],
    'cn': [
        {
            title: '1',
            value: '1'
        },
        {
            title: '2',
            value: '2'
        },
        {
            title: '3',
            value: '3'
        },
        {
            title: '4',
            value: '4'
        },
        {
            title: '5+',
            value: '5'
        }
    ],

}
export const serviceHouseRoomTypes = {
    'en': [
        {
            title: 'Any',
            value: '0'
        },
        {
            title: '1',
            value: '1'
        },
        {
            title: '2',
            value: '2'
        },
        {
            title: '3',
            value: '3'
        },
        {
            title: '4',
            value: '4'
        },
        {
            title: '5+',
            value: '5'
        }
    ],
    'th': [
        {
            title: 'ทั้งหมด',
            value: '0'
        },
        {
            title: '1',
            value: '1'
        },
        {
            title: '2',
            value: '2'
        },
        {
            title: '3',
            value: '3'
        },
        {
            title: '4',
            value: '4'
        },
        {
            title: '5+',
            value: '5'
        }
    ],
    'cn': [
        {
            title: '无线',
            value: '0'
        },
        {
            title: '1',
            value: '1'
        },
        {
            title: '2',
            value: '2'
        },
        {
            title: '3',
            value: '3'
        },
        {
            title: '4',
            value: '4'
        },
        {
            title: '5+',
            value: '5'
        }
    ]
}

export const serviceLandUnit = {
    "en": [
        {
            name: 'Rai',
            value: 'rai'
        },
        {
            name: 'Square meter',
            value: 'sqm'
        },
        {
            name: 'Square wa',
            value: 'sqw'
        },
        {
            name: 'Square feet',
            value: 'sqf'
        }
    ],
    "th": [
        {
            name: 'Rai',
            value: 'rai'
        },
        {
            name: 'Square meter',
            value: 'sqm'
        },
        {
            name: 'Square wa',
            value: 'sqw'
        },
        {
            name: 'Square feet',
            value: 'sqf'
        }
    ],
    "cn": [
        {
            name: '莱',
            value: 'rai'
        },
        {
            name: '平米',
            value: 'sqm'
        },
        {
            name: '平瓦',
            value: 'sqw'
        },
        {
            name: 'Square feet',
            value: 'sqf'
        }
    ]
}
